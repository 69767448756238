<template>
  <TosListVuetify :entityName="entityName" :headers="headers">
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="3">
            <v-text-field :label="tLabel('codice')" v-model="slotProps.data.codice" />
          </v-col>
          <v-col cols="3">
            <v-text-field :label="tLabel('nome')" v-model="slotProps.data.nome" />
          </v-col>
          <v-col cols="3">
            <v-text-field :label="tLabel('iso_size_type')" v-model="slotProps.data.isoSizeType" />
          </v-col>
          <v-col cols="3">
            <v-text-field  :label="tLabel('tipo_roro')" v-model="slotProps.data.tipoRoro" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
            <v-col cols="4">
              <v-text-field :label="tLabel('codice')+'*'" v-model="slotProps.data.codice" :rules="[rules.required]" :error-messages="slotProps.errors.codice"/>
            </v-col>
            <v-col cols="4">
              <v-text-field :label="tLabel('nome')+'*'" v-model="slotProps.data.nome" :rules="[rules.required]" :error-messages="slotProps.errors.nome" />
            </v-col>
            <v-col cols="4">
              <v-text-field :label="tLabel('descrizione')" v-model="slotProps.data.descrizione" :error-messages="slotProps.errors.descrizione"/>
            </v-col>           
        </v-row>
        <v-row>
            <v-col cols="4">
              <v-text-field :label="tLabel('peso_max')" v-model.number="slotProps.data.pesoMax" :error-messages="slotProps.errors.pesoMax" />
            </v-col>
            <v-col cols="4">
              <v-text-field :label="tLabel('profilo_ferroviario')" v-model="slotProps.data.profiloFerroviario" :error-messages="slotProps.errors.profiloFerroviario" />
            </v-col>
            <v-col cols="4">
              <v-text-field :label="tLabel('iso_size_type')" v-model="slotProps.data.isoSizeType" :error-messages="slotProps.errors.isoSizeType" />
            </v-col>              
        </v-row>      
        <v-row>            
            <v-col cols="4">
              <v-text-field :label="tLabel('tipo_roro')" v-model="slotProps.data.tipoRoro" :error-messages="slotProps.errors.tipoRoro" />
            </v-col>   
            <v-col cols="4">
              <v-checkbox :label="tLabel('uti_obbligatorio')" v-model="slotProps.data.utiObbligatorio" :error-messages="slotProps.errors.utiObbligatorio" />
            </v-col>       
            <v-col cols="2">
              <v-checkbox :label="tLabel('reefer')" v-model="slotProps.data.reefer" :error-messages="slotProps.errors.reefer" />
            </v-col>              
        </v-row>       
        <v-row>            
            <v-col cols="4">
              <v-select :label="tLabel('Sys type')" v-model="slotProps.data.sysType" :items="sysTypes" clearable :error-messages="slotProps.errors.sysType" />
            </v-col>   
            <v-col cols="4">
              <v-select :label="tLabel('Count Type')" v-model="slotProps.data.countType" :items="countTypes" clearable :error-messages="slotProps.errors.countType" />
            </v-col>                     
        </v-row>   
      </div>
    </template>
  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";
import EnumMixin from "../../mixins/yard/EnumMixin";

export default {
  name: "TipiUti",
  data() {
    return {
      entityName: "tipiUti",
      headers: [
        { text: "", value: "action" },
        { text: "Codice", value: "codice" },
        { text: "Nome", value: "nome" },
        { text: "Descrizione", value: "descrizione" },
        // { text: "Lunghezza Interna", value: "lunghezzaInterna" },
        // { text: "Lunghezza Esterna", value: "lunghezzaEsterna" },
        // { text: "Larghezza Interna", value: "larghezzaInterna" },
        // { text: "Larghezza Esterna", value: "larghezzaEsterna" },
        // { text: "Altezza Interna", value: "altezzaInterna" },
        // { text: "Altezza Esterna", value: "altezzaEsterna" },
        // { text: "Lunghezza Apertura", value: "lunghezzaApertura" },
        // { text: "Larghezza Apertura", value: "altezzaApertura" },
        // { text: "Capacità", value: "capacita" },
        // { text: "Tara", value: "tara" },
        { text: "Lunghezza Piedi", value: "lunghezzaPiedi" },
        { text: "Altezza Piedi", value: "altezzaPiedi" },
        { text: "Type", value: "type" },
        { text: "Peso Max", value: "pesoMax" },
        { text: "Profilo Ferroviario", value: "profiloFerroviario" },
        { text: "ISO Size Type", value: "isoSizeType" },
        { text: "UTI Obbligatorio", value: "utiObbligatorio" },
        { text: "Tipo Roro", value: "tipoRoro" },
        { text: "Sys. Type", value: "sysType" },
        { text: "Count Type", value: "countType" },
        // { text: "Pericoloso", value: "pericoloso" }
        { text: "Reefer", value: "reefer", formatValue:'formatBoolean' }
      ]
    };
  },
  components: {
    TosListVuetify: TosListVuetify
  },
  mixins: [FormatsMixins, EnumMixin],
  methods: {
    validate(item) {
       let errors = {};
       // TODO
       return errors;
    }
  },
};
</script>
